body {
  margin: 0;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "DM Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.btn-container {
  display: flex;
  gap: 5px;
}

.svgContainer {
  display: flex;
  position: relative;
  gap: 10px;

}

.cancelIcon {
  position: absolute;
  right: -25px;
  top: 60px;
  cursor: pointer;
}

.accWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}


.btn-tag {
  background-color: rgb(255, 255, 255);
  color: rgb(13, 31, 42);
  border: 1px solid rgb(13, 31, 42);
  font-size: 20px;
  min-width: 60px;
  font-weight: 700;
  line-height: 30px;
  border-radius: 9px;
  text-transform: none;
}

.inputWithButton {
  position: relative;
  width: 200px;
}

.inputWithButton input {
  width: 165px;
  padding: 5px 15px;
}

.inputWithButton input::placeholder {
  opacity: 1;
  color: rgb(13, 31, 42)
}

.inputWithButton .tag-icon {
  position: absolute;
  right: 10px;
  top: 9px;
  cursor: pointer;
}

.last-item {
  margin-top: auto;
  
}
@media screen and (max-width: 700px) {
  .last-item {
    margin-left: auto;
  }
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logo {
  margin-top: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logo img {
  width: 150px;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0.1em;
  color: white;
  font-size: 0.9rem;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
}

.MuiAccordionSummary-root {
  padding: 5px 35px;
}

.nav-name-outer {
  margin-left: 30px;
  padding: 10px 0px 10px 30px;
  border-left: 1px solid #A3AED0;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.nav-name-collapse {
  font-size: 0.88rem;
  cursor: pointer;
  text-align: left;
}

@media screen and (max-width: 700px) {
  .nav-name-outer {
    display: none;
  }
  .nav-icon-span {
    display: none;
  }
}